import React, { useState, useEffect } from "react";
import {
    FaExclamationCircle,
    FaBoxes,
    FaFileAlt,
    FaCheck,
    FaTrash,
    FaEdit,
    FaRegEye
} from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import Title from "../../../hook/Title";
import { Link } from "react-router-dom";
import { TableCom } from "../../../Components/TableCom";
const analytics = [
    {
        id: "1",
        title: "Total Employees",
        detail: "600",
    },
    {
        id: "2",
        title: "Total Male Employees",
        detail: "450",
    },
    {
        id: "3",
        title: "Total Female Employees",
        detail: "150",
    },
];

const Index = () => {
    const startingYear = [2022];
    const cYear = new Date().getFullYear();
    for (let i = 1; i <= 5; i++) {
        startingYear.push(cYear + i);
    }
    // Table Head Data
    const Thead = [
        "S.No",
        "Emp Name",
        "#Emp ID",
        "Department",
        "Designation",
        "Phone Number",
        "Join Date",
        "Status",
        "Actions",
    ];
    // ======================================= 
    const [thLength, setThlength] = useState(1);
    const [items, setItems] = useState([]);
    const [limit, setLimit] = useState(5);
    const [totalData, setTotalData] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [searchVal, setSearchVal] = useState("");
    const BASEURL = "http://jsonplaceholder.typicode.com/posts";
    useEffect(
        (currentPage) => {
            currentPage = currentPage ?? 0
            const getData = async () => {
                const res = await fetch(
                    `${BASEURL}/?q=${searchVal}&?_page=${currentPage}&_limit=${limit}`
                );
                const data = await res.json();
                const total = res.headers.get("x-total-count");
                setTotalData(total);
                setpageCount(Math.ceil(total / limit));
                setItems(data);
            };
            getData();
        },
        [limit, searchVal]
    );
    useEffect(() => {
        setThlength(Thead.length);
    }, [thLength]);
    const fetchData = async (currentPage) => {
        const res = await fetch(`${BASEURL}?_page=${currentPage}&_limit=${limit}`);
        const data = await res.json();
        return data;
    };
    const handlePageClick = async (data) => {
        console.log(data.selected);
        let currentPage = data.selected + 1;
        const myData = await fetchData(currentPage);
        setItems(myData);
    };
    const handleChange = (e) => {
        setLimit(e.target.value);
    };
    // search data in api using search box
    const hangleSearchVal = (e) => {
        setSearchVal(e.target.value);
    };
    Title("Dashboard");
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <h4 className="primHeading my-2 mb-4">HR <span> Dashboard</span></h4>
                <Link className="main-btn" to="/add-employee">Add Employee</Link>
            </div>
            <Row>
                {analytics.map((item, index) => {
                    return (
                        <Col
                            key={index}
                            lg={4}
                            sm={6}
                            className={`analyticsWrap${item.id} mb-2`}
                        >
                            <div className="dashboard-anatlytics d-flex align-items-center justify-content-between flex-wrap">
                                <div>
                                    <h3>{item.title}</h3>
                                    <h4>{item.detail}</h4>
                                </div>
                                <div className={`analyticsIcon${item.id} analyticsIcon`}>
                                    {item.id == 1 ? (
                                        <FaFileAlt className="icons" />
                                    ) : item.id == 2 ? (
                                        <FaBoxes className="icons" />
                                    ) : item.id == 3 ? (
                                        <FaExclamationCircle className="icons" />
                                    ) : item.id == 4 ? (
                                        <FaCheck className="icons" />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
            <Row>
                <Col>
                    <div className="box">
                        <h6 className="sectionHeading my-2 mb-4">Employees List</h6>
                        <hr className="my-4" />
                        {/* Table start here */}
                        <TableCom
                            limit={limit}
                            totalData={totalData}
                            handleChange={handleChange}
                            hangleSearchVal={hangleSearchVal}
                            pageCount={pageCount}
                            handlePageClick={handlePageClick}
                        >
                            <div className="customTable2 w-100 table-responsive">
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                            {Thead?.map((th, index) => {
                                                return <th key={index}>{th}</th>;
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items == "" || items == undefined || items == null ? (
                                            <tr>
                                                <td colSpan={thLength}>
                                                    <p className="my-2 smText text-decoration-underline text-danger">
                                                        No Record Found
                                                    </p>
                                                </td>
                                            </tr>
                                        ) : (
                                            items.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.id}</td>
                                                        <td>
                                                            {/* asssigned user avatar */}
                                                            <img
                                                                className="tdAvatar"
                                                                src={require("../../../assets/images/avatar.png")}
                                                                alt="assigned user image"
                                                            />
                                                            {item.title.slice(0, 10)}
                                                        </td>
                                                        <td>{item.title.slice(0, 5)}</td>
                                                        <td>{item.title.slice(0, 5)}</td>
                                                        <td>{item.title.slice(0, 5)}</td>
                                                        <td>{item.title.slice(0, 5)}</td>
                                                        <td>{item.title.slice(0, 5)}</td>
                                                        <td>
                                                            <span
                                                                className={
                                                                    item.id == 1
                                                                        ? "customBadge bgBlue"
                                                                        : item.id == 2
                                                                            ? "customBadge bgRed"
                                                                            : item.id == 3
                                                                                ? "customBadge bgGreen"
                                                                                : "customBadge bgOrange"
                                                                }
                                                            >
                                                                {item.title.slice(0, 3)}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <Link
                                                                    to="/edit-employee"
                                                                    className="actionIcon bgGreen"
                                                                >
                                                                    <FaEdit />
                                                                </Link>
                                                                <button
                                                                    className="actionIcon bgRed border-none"
                                                                >
                                                                    <FaTrash />
                                                                </button>
                                                                <Link
                                                                    to="/view-employee"
                                                                    className="actionIcon bgOrange"
                                                                >
                                                                    <FaRegEye />
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </TableCom>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Index;