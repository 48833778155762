import React, { useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Title from "../../../hook/Title";
import GoBack from "../../../Components/GoBack";
import { Link, useNavigate } from "react-router-dom";

import {
    FaHeading,
} from "react-icons/fa";

const CreateAnnouncement = () => {
    const [selected, setSelected] = useState("male");
    const [selectedFile, setSelectedFile] = useState(null);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");


    Title("Create Announcement");
    const navigate = useNavigate();
    const fileInput = useRef(null);
    // Select input file from your PC
    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    // Show Toast msg if user put invalid or Null crediantials
    const showInvCredError = () => {
        toast.error(
            "Something's wrong! Please make sure you have fill all the required fields",
            {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "colored",
            }
        );
    };

    // Create Announcement form submittion:
    const handleCreateAnnouncement = () => {
        if (title !== "" || description !== "") {
            // Call Apis 
            console.log("title", title);
            console.log("description", description);

            setTitle("");
            setDescription("");

        } else {
            showInvCredError();
        }
    }

    return (
        <>
            <div className="d-flex align-items-center mb-1 flex-wrap">
                <GoBack />
                <h4 className="primHeading mb-0">Create New <span>Notice</span></h4>
            </div>
            <div className="box mt-5">
                <Row>
                    <Col xl={8} lg={10}>
                        <Row>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="title">
                                        Title<span className="text-danger">*</span>
                                    </label>
                                    <div className="position-relative">
                                        <button
                                            className="position-absolute show-password"
                                            type="button">
                                            <FaHeading />
                                        </button>
                                        <input
                                            id="title"
                                            type="text"
                                            placeholder="Enter Title"
                                            className="primary-inputs"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="profile">Attachment:</label>
                                    <input type="file" ref={fileInput} id="profile" className="d-none" name="file" accept="image/*" onChange={changeHandler} />
                                    <div className="p-0 primary-inputs d-flex justify-content-between align-items-center">
                                        <p className="mb-0 mx-3 exsmText">{selectedFile == null ? "No file chosen" : selectedFile.name}</p>
                                        <button className="formUploadBtn" onClick={() => fileInput.current.click()}>Choose File</button>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="desc">Description<span className="text-danger">*</span> </label>
                                    <textarea
                                        name="description"
                                        id="desc"
                                        className="primary-inputs px-3"
                                        rows="5"
                                        placeholder="Enter Description"
                                        onChange={(e) => setDescription(e.target.value)}
                                        value={description}
                                    ></textarea>
                                </div>
                            </Col>
                            <Col sm={12} className="mt-4">
                                <button type="button" className="main-btn me-4" onClick={handleCreateAnnouncement} >Create</button>
                                <Link type="button" to="/notice-board" className="main-btn sec-btn me-4">Cancel</Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default CreateAnnouncement; 