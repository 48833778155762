import React, { useState, useEffect } from "react";
import {
    FaTimes,
    FaCheck,
    FaRegEye
} from "react-icons/fa";
import {
    AiOutlineClose
} from "react-icons/ai";
import { Row, Col } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Title from "../../../hook/Title";
import { TableCom } from "../../../Components/TableCom";
import CustomModal from "../../../Components/CustomModal";


const Leavesapplication = () => {
    const startingYear = [2022];
    const cYear = new Date().getFullYear();
    for (let i = 1; i <= 5; i++) {
        startingYear.push(cYear + i);
    }

    const [modalShow, setModalShow] = useState(false);
    // Table Head Data
    const Thead = [
        "S.No",
        "Emp Name",
        "Leave Type",
        "From",
        "To",
        "Days",
        "Reason",
        "Applied On",
        "Status",
        "Actions",
    ];
    // ======================================= 
    const [thLength, setThlength] = useState(1);
    const [items, setItems] = useState([]);
    const [limit, setLimit] = useState(5);
    const [totalData, setTotalData] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [searchVal, setSearchVal] = useState("");
    const BASEURL = "http://jsonplaceholder.typicode.com/posts";
    useEffect(
        (currentPage) => {
            currentPage = currentPage ?? 0
            const getData = async () => {
                const res = await fetch(
                    `${BASEURL}/?q=${searchVal}&?_page=${currentPage}&_limit=${limit}`
                );
                const data = await res.json();
                const total = res.headers.get("x-total-count");
                setTotalData(total);
                setpageCount(Math.ceil(total / limit));
                setItems(data);
            };
            getData();
        },
        [limit, searchVal]
    );
    useEffect(() => {
        setThlength(Thead.length);
    }, [thLength]);

    const fetchData = async (currentPage) => {
        const res = await fetch(`${BASEURL}?_page=${currentPage}&_limit=${limit}`);
        const data = await res.json();
        return data;
    };

    const handlePageClick = async (data) => {
        console.log(data.selected);
        let currentPage = data.selected + 1;
        const myData = await fetchData(currentPage);
        setItems(myData);
    };

    const handleChange = (e) => {
        setLimit(e.target.value);
    };

    // search data in api using search box
    const hangleSearchVal = (e) => {
        setSearchVal(e.target.value);
    };
    Title("Dashboard");
    return (
        <>
            <h4 className="primHeading my-2 mb-4">Leave<span> Applications</span></h4>
            <Row>
                <Col>
                    <div className="box">
                        <h6 className="sectionHeading my-2 mb-4">Leaves Summary</h6>
                        <hr className="my-4" />
                        {/* Table start here */}
                        <TableCom
                            limit={limit}
                            totalData={totalData}
                            handleChange={handleChange}
                            hangleSearchVal={hangleSearchVal}
                            pageCount={pageCount}
                            handlePageClick={handlePageClick}
                        >
                            <div className="customTable2 w-100 table-responsive">
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                            {Thead?.map((th, index) => {
                                                return <th key={index}>{th}</th>;
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items == "" || items == undefined || items == null ? (
                                            <tr>
                                                <td colSpan={thLength}>
                                                    <p className="my-2 smText text-decoration-underline text-danger">
                                                        No Record Found
                                                    </p>
                                                </td>
                                            </tr>
                                        ) : (
                                            items.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.id}</td>
                                                        <td>
                                                            {/* asssigned user avatar */}
                                                            <img
                                                                className="tdAvatar"
                                                                src={require("../../../assets/images/avatar.png")}
                                                                alt="assigned user image"
                                                            />
                                                            {item.title.slice(0, 10)}
                                                        </td>
                                                        <td>{item.title.slice(0, 5)}</td>
                                                        <td>{item.title.slice(0, 5)}</td>
                                                        <td>{item.title.slice(0, 5)}</td>
                                                        <td>{item.title.slice(0, 5)}</td>
                                                        <td>{item.title.slice(0, 5)}</td>
                                                        <td>{item.title.slice(0, 5)}</td>
                                                        <td>
                                                            <span
                                                                className={
                                                                    item.id == 1
                                                                        ? "customBadge bgBlue"
                                                                        : item.id == 2
                                                                            ? "customBadge bgRed"
                                                                            : item.id == 3
                                                                                ? "customBadge bgGreen"
                                                                                : "customBadge bgOrange"
                                                                }
                                                            >
                                                                {item.title.slice(0, 3)}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <OverlayTrigger overlay={<Tooltip>Accept</Tooltip>}>
                                                                    <button className="actionIcon bgGreen"><FaCheck /></button>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger overlay={<Tooltip>Reject</Tooltip>}>
                                                                    <button className="actionIcon bgRed border-none"><AiOutlineClose /></button>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger overlay={<Tooltip>View Description</Tooltip>}>
                                                                    <button className="actionIcon bgOrange" onClick={() => setModalShow(true)}> <FaRegEye /> </button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </TableCom>
                    </div>
                </Col>
            </Row>
            {/* ======== Leave Application Modal ======== */}
            <CustomModal CustomModal show={modalShow} onHide={() => setModalShow(false)}>
                <button onClick={() => setModalShow(false)} className="modal-cancel">
                    <FaTimes />
                </button>
                <div className="modalContent">
                    <div className="text-start">
                        <h3 className="modalPrimHeading">Leave Application</h3>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="leaveType">Description  </label>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsa, rem.</p>
                        </div>
                    </div>
                    {/* modal buttons */}
                    <button className="main-btn m-2" type="button" onClick={() => { setModalShow(false) }}>Accept</button>
                    <button className="sec-btn m-2" type="button" onClick={() => setModalShow(false)}>Reject</button>
                </div>
            </CustomModal>
        </>
    );
};
export default Leavesapplication;