import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Routes from "./Routes/MainRoutes";
import "../src/assets/css/style.css";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
function App() {
  return (
    <>
     <Router basename="/">
      <Routes />
      <ToastContainer />
     </Router>
    </>
  );
}

export default App;
