import { combineReducers } from 'redux';
import persistReducer from "redux-persist/es/persistReducer";
import storage from 'redux-persist/lib/storage';
import AuthReducer from './AuthReducer';
import HrPortaReducer from './HrPortaReducer';


const persistConfig = {
    key: 'root',
    storage,
    // whitelist: ['auth']
}
const mainReducer = combineReducers({
    AuthReducer: AuthReducer,
    HrPortaReducer: HrPortaReducer,
})

export default persistReducer(persistConfig, mainReducer);