import React, { useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Title from "../../../hook/Title";
import GoBack from "../../../Components/GoBack";
import { useNavigate } from "react-router-dom";
import BASEURL from "../../../BaseUrl"; 
import {
    FaUserAlt,
    FaEnvelope,
    FaAddressCard,
    FaSortNumericDownAlt
} from "react-icons/fa";


const EditEmployee = () => {
    const [selected, setSelected] = useState("male");
    const [selectedFile, setSelectedFile] = useState(null);
    // Registration code starts here: 
    const [employeeName, setEmployeeName] = useState("");
    const [employeeFatherName, setEmployeeFatherName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [cnic_passport, setCnic_passport] = useState("");
    const [dateOfJoining, setDateOfJoining] = useState("");
    const [department, setDepartment] = useState("");
    const [subDepartment, setSubDepartment] = useState("");
    const [designation, setDesignation] = useState("");
    const [salary, setSalary] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");

    Title("Edit Employee");
    const navigate = useNavigate();
    const fileInput = useRef(null);
    // Select input file from your PC
    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    // Show Toast msg if user put invalid or Null crediantials
    const showInvCredError = () => {
        toast.error(
            "Something's wrong! Please make sure you have fill all the required fields",
            {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "colored",
            }
        );
    };
    // Show Toast msg if user put invalid or already registered crediantials
    const showApiError = () => {
        toast.error("Entered email id already registered with us", {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });
    };
    // Show Toast msg if user Updated Sucessfully
    const showSucessToast = () => {
        toast.success("Updated Successfully !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });
    };


    const regesterData = async () => {
        //  Check that the user fill all the required fields
        if (
            employeeName !== "" &&
            email !== "" &&
            cnic_passport !== "" &&
            dateOfJoining !== "" &&
            department !== ""
        ) {
            console.log(
                "user fill fields ==>",
                employeeName,
                email,
                cnic_passport,
                dateOfJoining,
                department
            );

            let result = await fetch(`${BASEURL}/api/users`, {
                method: "POST",
                body: JSON.stringify({
                    employeeName,
                    email,
                    cnic_passport,
                    dateOfJoining,
                    department,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            result = await result.json();
            console.log(
                "🚀 ~ file: Register.js ~ line 57 ~ regesterData ~ result",
                result
            );
            if (result.success == true) {
                showSucessToast();
                ClearData();
                navigate("/dashboard");
            } else {
                showApiError();
            }
        } else {
            showInvCredError();
        }
    };
    //  Clear all the input fields
    const ClearData = () => {
        setEmployeeName("");
        setEmail("");
        setCnic_passport("");
        setDateOfJoining("");
        setDepartment("");
    };
    const handleChange = (e) => {
        console.log(e.target.value);
        setSelected(e.target.value);
    };
    return (
        <>
            <div className="d-flex align-items-center mb-1 flex-wrap">
                <GoBack />
                <h4 className="primHeading mb-0">Add New<span> Employee </span></h4>
            </div>
            <div className="box mt-5">
                <Row>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="Name">
                                Employee Name<span className="text-danger">*</span>
                            </label>
                            <div className="position-relative">
                                <button
                                    className="position-absolute show-password"
                                    type="button">
                                    <FaUserAlt />
                                </button>
                                <input
                                    id="Name"
                                    type="text"
                                    placeholder="Enter Full Name"
                                    className="primary-inputs"
                                    value={employeeName}
                                    onChange={(e) => setEmployeeName(e.target.value)}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="fName">
                                Father Name<span className="text-danger">*</span>
                            </label>
                            <div className="position-relative">
                                <button
                                    className="position-absolute show-password"
                                    type="button">
                                    <FaUserAlt />
                                </button>
                                <input
                                    id="fName"
                                    type="text"
                                    placeholder="Enter Father Name"
                                    className="primary-inputs"
                                    value={employeeFatherName}
                                    onChange={(e) => employeeFatherName(e.target.value)}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="email">
                                Email<span className="text-danger">*</span>
                            </label>
                            <div className="position-relative">
                                <button
                                    className="position-absolute show-password"
                                    type="button">
                                    <FaEnvelope />
                                </button>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="Enter Email"
                                    className="primary-inputs"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="phone">
                                Phone Number<span className="text-danger">*</span>
                            </label>
                            <div className="position-relative">
                                <button
                                    className="position-absolute show-password"
                                    type="button">
                                    <FaSortNumericDownAlt />
                                </button>
                                <input
                                    id="phone"
                                    type="number"
                                    placeholder="Enter Phone Number"
                                    className="primary-inputs"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="department">
                                Department <span className="text-danger">*</span>
                            </label>
                            <select
                                name="department"
                                id="department"
                                onChange={(e) => setDepartment(e.target.value)}
                                className="primary-inputs px-2"
                                value={department}>
                                <option value="#" disabled>
                                    Select Department
                                </option>
                                <option value="admin">Admin</option>
                                <option value="sales">Sales</option>
                                <option value="development">Web & App</option>
                                <option value="hr">Human Resource</option>
                                <option value="finance">Finance</option>
                            </select>

                        </div>
                    </Col>
                    {department === "development" ? (
                        <Col lg={6}>
                            <div className="registration-inp-box my-3">
                                <label className="d-block" htmlFor="subDepartment">
                                    Sub Department <span className="text-danger">*</span>
                                </label>
                                <select
                                    name="subDepartment"
                                    id="subDepartment"
                                    className="primary-inputs px-2"
                                    onChange={(e) => setSubDepartment(e.target.value)}
                                    value={subDepartment}>
                                    <option value="#" disabled>
                                        Select Department
                                    </option>
                                    <option value="uiux">UI/UX Designing</option>
                                    <option value="logo">Logo Designing</option>
                                    <option value="frontend">Frontend Developer</option>
                                    <option value="cms">CMS Developer</option>
                                    <option value="mern">Mern Stack Developer</option>
                                    <option value="backend">Backend Developer</option>
                                    <option value="fullStack">Full Stack Developer</option>
                                    <option value="app">App Developer</option>
                                </select>
                            </div>
                        </Col>
                    ) : (
                        ""
                    )}
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="designation">
                                Designation <span className="text-danger">*</span>
                            </label>
                            <select
                                name="designation"
                                id="designation"
                                className="primary-inputs px-2"
                                onChange={(e) => setDesignation(e.target.value)}
                                value={designation}
                            >
                                <option value="#" disabled>
                                    Select Designation
                                </option>
                                <option value="intern">Intern</option>
                                <option value="junior">Junior</option>
                                <option value="mid">Mid</option>
                                <option value="senior">Senior</option>
                                <option value="atl">Associate Team Lead</option>
                                <option value="tl">Team Lead</option>
                                <option value="am">Associate Manager</option>
                                <option value="manager">Manager</option>
                                <option value="hod">Head of Department</option>
                                <option value="avp">Associate Vice President</option>
                                <option value="vp">Vice President</option>
                                <option value="president">President</option>
                            </select>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="salary">
                                Salary<span className="text-danger">*</span>
                            </label>
                            <div className="position-relative">
                                <button
                                    className="position-absolute show-password"
                                    type="button">
                                    <FaSortNumericDownAlt />
                                </button>
                                <input
                                    id="salary"
                                    type="number"
                                    placeholder="Enter Salary"
                                    className="primary-inputs"
                                    value={salary}
                                    onChange={(e) => setSalary(e.target.value)}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="meritialStatus">
                                Marital Status <span className="text-danger">*</span>
                            </label>
                            <select
                                name="meritialStatus"
                                id="meritialStatus"
                                className="primary-inputs px-2"
                                onChange={(e) => setMaritalStatus(e.target.value)}
                                value={maritalStatus}
                            >
                                <option value="#" disabled>
                                    Select
                                </option>
                                <option value="single">Single</option>
                                <option value="married">Married</option>
                            </select>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="cnic">
                                Cnic <span className="text-danger">*</span>
                            </label>
                            <div className="position-relative">
                                <button
                                    className="position-absolute show-password"
                                    type="button">
                                    <FaAddressCard />
                                </button>
                                <input
                                    id="cnic"
                                    type="number"
                                    placeholder="Enter Cnic Number"
                                    className="primary-inputs"
                                    value={cnic_passport}
                                    onChange={(e) => setCnic_passport(e.target.value)}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="dob">
                                Date Of Birth<span className="text-danger">*</span>
                            </label>
                            <input
                                type="date"
                                className="prim-date"
                                name="dateOfJoining"
                                id="dob"
                                value={dateOfJoining}
                                onChange={(e) => setDateOfJoining(e.target.value)}
                            />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="jod">
                                Joining Date<span className="text-danger">*</span>
                            </label>
                            <input
                                type="date"
                                className="prim-date"
                                name="dateOfJoining"
                                id="jod"
                                value={dateOfJoining}
                                onChange={(e) => setDateOfJoining(e.target.value)}
                            />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="profile">Upload profile</label>
                            <input type="file" ref={fileInput} id="profile" className="d-none" name="file" onChange={changeHandler} />
                            <div className="p-0 primary-inputs d-flex justify-content-between align-items-center">
                                <p className="mb-0 mx-3 exsmText">{selectedFile == null ? "No file chosen" : selectedFile.name}</p>
                                <button className="formUploadBtn" onClick={() => fileInput.current.click()}>Choose File</button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block">
                                Gender<span className="text-danger">*</span>
                            </label>
                            <div className="d-flex align-items-center flex-wrap">
                                <label className="radioWrap my-2 me-5">
                                    <input
                                        type="radio"
                                        name="radio"
                                        value="male"
                                        checked={selected === "male"}
                                        onChange={handleChange}
                                    />
                                    <span className="chkmark"></span>
                                    Male
                                </label>
                                <label className="radioWrap my-2 me-5">
                                    <input
                                        type="radio"
                                        name="radio"
                                        value="female"
                                        checked={selected === "female"}
                                        onChange={handleChange}
                                    />
                                    <span className="chkmark"></span>
                                    Female
                                </label>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="address">Address<span className="text-danger">*</span> </label>
                            <textarea
                                name="address"
                                id="address"
                                className="primary-inputs px-3"
                                rows="5"
                                placeholder="Enter Address"
                            // onChange={(e) => setDescription(e.target.value)}
                            // value={description}
                            ></textarea>
                        </div>
                    </Col>

                    <Col sm={12}>
                        <button
                            type="button"
                            className="main-btn mt-4"
                            onClick={regesterData}>Update</button>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default EditEmployee; 