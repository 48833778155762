import React, { useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Title from "../../../hook/Title";
import GoBack from "../../../Components/GoBack";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import LoadingSpinner from "../../utils/LoadingSpinner";
import { createEmployeeRoute } from "../../../utils/APIRoutes";
import "react-toastify/dist/ReactToastify.css";
import {
    FaUserAlt,
    FaEnvelope,
    FaAddressCard,
    FaSortNumericDownAlt
} from "react-icons/fa";


const AddEmployee = () => {
    Title("Add Employee");
    let toastOptions = {
        position: "top-left",
        autoClose: 5000,
        pauseOnHover: false,
        draggable: false,
        theme: "dark",
    };
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    // ---- States of inputs in the form of key value pair name:value ------ // 
    const [values, setValues] = useState({
        employeeName: "",
        fatherName: "",
        pseudoName: "",
        email: "",
        cnic_passport: "",
        phone: "",
        dateOfJoining: "",
        department: "",
        subDepartment: "",
        designation: "",
        salary: "",
        meritialStatus: "",
        dateOfBirth: "",
        gender: "",
        address: ""
    });
    // ---- Getting values of inputs ------ //  
    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };
    // ---- Validate Form ------ //
    let hasError = false;
    const validateForm = () => {
        const newErrors = {};
        const {
            employeeName,
            pseudoName,
            cnic_passport,
            dateOfJoining,
            designation,
            dateOfBirth,
            fatherName,
            department,
            email,
            phone,
            salary,
            gender,
        } = values;
        if (employeeName === "" || employeeName === undefined || employeeName === null) {
            hasError = true;
            setIsLoading(false);
            newErrors.employeeName = 'Name is required !';
        }
        if (fatherName === "" || fatherName === undefined || fatherName === null) {
            hasError = true;
            setIsLoading(false);
            newErrors.fatherName = 'Father/Husband Name is required !';
        }
        if (cnic_passport === "" || cnic_passport === undefined || cnic_passport === null) {
            hasError = true;
            setIsLoading(false);
            newErrors.cnic_passport = 'CNIC is required !';
        } else if (cnic_passport.length !== 13) {
            hasError = true;
            setIsLoading(false);
            newErrors.cnic_passport = 'Length Must be 13 digits !';
        }
        if (dateOfJoining === "" || dateOfJoining === undefined || dateOfJoining === null) {
            hasError = true;
            setIsLoading(false);
            newErrors.dateOfJoining = 'Joining Date is required !';
        }
        if (designation === "" || designation === undefined || designation === null) {
            hasError = true;
            setIsLoading(false);
            newErrors.designation = 'Designation is required !';
        }
        if (dateOfBirth === "" || dateOfBirth === undefined || dateOfBirth === null) {
            hasError = true;
            setIsLoading(false);
            newErrors.dateOfBirth = 'Date Of Birth is required !';

        }
        if (gender === "" || gender === undefined || gender === null) {
            hasError = true;
            setIsLoading(false);
            newErrors.gender = 'Gender is required !';

        }
        if (phone === "" || phone === undefined || phone === null) {
            hasError = true;
            setIsLoading(false);
            newErrors.phone = 'Phone is required !';

        }
        if (department === "" || department === undefined || department === null) {
            hasError = true;
            setIsLoading(false);
            newErrors.department = 'department is required !';

        }
        if (salary === "" || salary === undefined || salary === null) {
            hasError = true;
            setIsLoading(false);
            newErrors.salary = 'salary is required !';

        }
        if (email === "" || email === undefined || email === null) {
            hasError = true;
            setIsLoading(false);
            newErrors.email = 'Email is required !';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            hasError = true;
            setIsLoading(false);
            newErrors.email = 'Email is not valid !';
        }
        setErrors(newErrors);
    };
    // ---- Form Submittion Function ------ // 
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        validateForm();
        if (hasError === false) {
            const { employeeName,
                fatherName,
                pseudoName,
                email,
                cnic_passport,
                phone,
                dateOfJoining,
                department,
                subDepartment,
                designation,
                salary,
                maritialStatus,
                dateOfBirth,
                gender,
                address } = values;
            const { data } = await axios.post(createEmployeeRoute, {
                employeeName,
                fatherName,
                pseudoName,
                email,
                cnic_passport,
                phone,
                dateOfJoining,
                department,
                subDepartment,
                designation,
                salary,
                maritialStatus,
                dateOfBirth,
                gender,
                address
            });
            if (data.success === true) {
                toast.success(data.message, toastOptions, toastOptions['position'] = "top-right");
                setValues({
                    employeeName: "",
                    fatherName: "",
                    pseudoName: "",
                    email: "",
                    cnic_passport: "",
                    phone: "",
                    dateOfJoining: "",
                    department: "",
                    subDepartment: "",
                    designation: "",
                    salary: "",
                    maritialStatus: "",
                    dateOfBirth: "",
                    gender: "",
                    address: ""
                });
                setIsLoading(false);
                navigate("/employees");
            } else {
                toast.error(data.message, toastOptions, toastOptions['position'] = "top-left");
                setIsLoading(false);
            }
        } else {
            toast.error("Please Fill Out The Required Fields !", toastOptions, toastOptions['position'] = "bottom-right");
        }
    };

    return (
        <>
            <div className="d-flex align-items-center mb-1 flex-wrap">
                <GoBack />
                <h4 className="primHeading mb-0">Add New<span> Employee </span></h4>
            </div>
            <form className="box mt-5" onSubmit={handleSubmit}>
                <Row>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="employeeName">Employee Name<span className="text-danger">*</span></label>
                            <div className="position-relative">
                                <button className="position-absolute show-password" type="button"><FaUserAlt /></button>
                                <input
                                    name="employeeName"
                                    id="employeeName"
                                    type="text"
                                    placeholder="Enter Full Name"
                                    className="primary-inputs"
                                    value={values.employeeName}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            {errors.employeeName && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.employeeName}</p>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="fatherName">
                                Father/Husband Name<span className="text-danger">*</span>
                            </label>
                            <div className="position-relative">
                                <button
                                    className="position-absolute show-password"
                                    type="button">
                                    <FaUserAlt />
                                </button>
                                <input
                                    name="fatherName"
                                    id="fatherName"
                                    type="text"
                                    placeholder="Enter Father Name"
                                    className="primary-inputs"
                                    value={values.fatherName}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            {errors.fatherName && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.fatherName}</p>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="email">
                                Email<span className="text-danger">*</span>
                            </label>
                            <div className="position-relative">
                                <button
                                    className="position-absolute show-password"
                                    type="button">
                                    <FaEnvelope />
                                </button>
                                <input
                                    name="email"
                                    id="email"
                                    type="email"
                                    placeholder="Enter Email"
                                    className="primary-inputs"
                                    value={values.email}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            {errors.email && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.email}</p>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="phone">
                                Phone Number<span className="text-danger">*</span>
                            </label>
                            <div className="position-relative">
                                <button
                                    className="position-absolute show-password"
                                    type="button">
                                    <FaSortNumericDownAlt />
                                </button>
                                <input
                                    name="phone"
                                    id="phone"
                                    type="number"
                                    placeholder="Enter Phone Number"
                                    className="primary-inputs"
                                    value={values.phone}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            {errors.phone && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.phone}</p>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="department">
                                Department <span className="text-danger">*</span>
                            </label>
                            <select
                                name="department"
                                id="department"
                                onChange={(e) => handleChange(e)}
                                className={` primary-inputs px-2`}
                                value={values.department}>
                                <option value="">Select Department</option>
                                <option value="admin">Admin</option>
                                <option value="sales">Sales</option>
                                <option value="development">Web & App</option>
                                <option value="hr">Human Resource</option>
                                <option value="finance">Finance</option>
                            </select>
                            {errors.department && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.department}</p>}
                        </div>
                    </Col>
                    {values.department === "development" ? (
                        <Col lg={6}>
                            <div className="registration-inp-box my-3">
                                <label className="d-block" htmlFor="subDepartment">Sub Department</label>
                                <select
                                    name="subDepartment"
                                    id="subDepartment"
                                    className="primary-inputs px-2"
                                    onChange={(e) => handleChange(e)}
                                    value={values.subDepartment}>
                                    <option value="">Select Sub Department</option>
                                    <option value="uiux">UI/UX</option>
                                    <option value="logo">Logo</option>
                                    <option value="frontend">Frontend</option>
                                    <option value="cms">CMS</option>
                                    <option value="backend">Backend</option>
                                    <option value="app">Mobile App</option>
                                </select>
                            </div>
                        </Col>
                    ) : (
                        ""
                    )}
                    {
                        values.department === "sales" ? (
                            <>
                                <Col lg={6}>
                                    <div className="registration-inp-box my-3">
                                        <label className="d-block" htmlFor="designation"> Designation <span className="text-danger">*</span></label>
                                        <select
                                            name="designation"
                                            id="designation"
                                            className={` primary-inputs px-2`}
                                            onChange={(e) => handleChange(e)}
                                            value={values.designation}>
                                            <option value=""> Select Designation </option>
                                            <option value="lead_generator">Lead Generator</option>
                                            <option value="intern">Intern</option>
                                            <option value="junior">Junior</option>
                                            <option value="mid">Mid</option>
                                            <option value="senior">Senior</option>
                                            <option value="associate_manager">Associate Manager</option>
                                            <option value="manager">Manager</option>
                                            <option value="unit_head">Unit Head</option>
                                            <option value="head_of_department">Head of Department</option>
                                        </select>
                                        {errors.designation && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.designation}</p>}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="registration-inp-box my-3">
                                        <label className="d-block" htmlFor="pseudoName"> Pseudo Name <span className="text-danger">*</span></label>
                                        <div className="position-relative">
                                            <button
                                                className="position-absolute show-password"
                                                type="button">
                                                <FaUserAlt />
                                            </button>
                                            <input
                                                id="pseudoName"
                                                name="pseudoName"
                                                type="text"
                                                placeholder="Enter Pseudo Name"
                                                className="primary-inputs"
                                                value={values.pseudoName}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </>
                        ) : (
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="designation"> Designation <span className="text-danger">*</span></label>
                                    <select
                                        name="designation"
                                        id="designation"
                                        className={` primary-inputs px-2`}
                                        onChange={(e) => handleChange(e)}
                                        value={values.designation}>
                                        <option value=""> Select Designation</option>
                                        <option value="intern">Intern</option>
                                        <option value="junior">Junior</option>
                                        <option value="mid">Mid</option>
                                        <option value="senior">Senior</option>
                                        <option value="associate_team_lead">Associate Team Lead</option>
                                        <option value="team_lead">Team Lead</option>
                                        <option value="associate_manager">Associate Manager</option>
                                        <option value="manager">Manager</option>
                                        <option value="head_of_department">Head of Department</option>
                                        <option value="associate_vice_president">Associate Vice President</option>
                                        <option value="vice_president">Vice President</option>
                                        <option value="president">President</option>
                                    </select>
                                    {errors.designation && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.designation}</p>}
                                </div>
                            </Col>
                        )
                    }
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="salary">
                                Salary<span className="text-danger">*</span>
                            </label>
                            <div className="position-relative">
                                <button
                                    className="position-absolute show-password"
                                    type="button">
                                    <FaSortNumericDownAlt />
                                </button>
                                <input
                                    id="salary"
                                    name="salary"
                                    type="number"
                                    placeholder="Enter salary"
                                    className="primary-inputs"
                                    value={values.salary}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            {errors.salary && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.salary}</p>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="meritialStatus"> Marital Status</label>
                            <select
                                name="meritialStatus"
                                id="meritialStatus"
                                className="primary-inputs px-2"
                                onChange={(e) => handleChange(e)}
                                value={values.meritialStatus}>
                                <option value="#" disabled>Select</option>
                                <option value="single">Single</option>
                                <option value="married">Married</option>
                            </select>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="cnic_passport">
                                Cnic <span className="text-danger">*</span>
                            </label>
                            <div className="position-relative">
                                <button className="position-absolute show-password" type="button">
                                    <FaAddressCard />
                                </button>
                                <input
                                    id="cnic_passport"
                                    type="number"
                                    name="cnic_passport"
                                    placeholder="Enter Cnic Number"
                                    className="primary-inputs"
                                    value={values.cnic_passport}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            {errors.cnic_passport && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.cnic_passport}</p>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="dateOfBirth">Date Of Birth<span className="text-danger">*</span></label>
                            <input
                                type="date"
                                className="prim-date"
                                name="dateOfBirth"
                                id="dateOfBirth"
                                value={values.dateOfBirth}
                                onChange={(e) => handleChange(e)}
                            />
                            {errors.dateOfBirth && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.dateOfBirth}</p>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="dateOfJoining">
                                Joining Date<span className="text-danger">*</span>
                            </label>
                            <input
                                type="date"
                                className="prim-date"
                                name="dateOfJoining"
                                id="jod"
                                value={values.dateOfJoining}
                                onChange={(e) => handleChange(e)}
                            />
                            {errors.dateOfJoining && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.dateOfJoining}</p>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block">
                                Gender<span className="text-danger">*</span>
                            </label>
                            <div className="d-flex align-items-center flex-wrap">
                                <label className="radioWrap my-2 me-5">
                                    <input
                                        type="radio"
                                        name="gender"
                                        value="male"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <span className="chkmark"></span>Male
                                </label>
                                <label className="radioWrap my-2 me-5">
                                    <input
                                        type="radio"
                                        name="gender"
                                        value="female"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <span className="chkmark"></span> Female
                                </label>
                            </div>
                            {errors.gender && <p className="text-danger fw-500 fs-13 my-2 text-capitalize">{errors.gender}</p>}
                        </div>
                    </Col>
                    {/* <Col lg={6}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="profile">Upload profile</label>
                            <input type="file" ref={fileInput} id="profile" className="d-none" name="file" accept="image/*" onChange={changeHandler} />
                            <div className="p-0 primary-inputs d-flex justify-content-between align-items-center">
                                <p className="mb-0 mx-3 exsmText">{selectedFile == null ? "No file chosen" : selectedFile.name}</p>
                                <button className="formUploadBtn" onClick={() => fileInput.current.click()}>Choose File</button>
                            </div>
                        </div>
                    </Col> */}
                    <Col sm={12}>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="address">Address</label>
                            <textarea
                                name="address"
                                id="address"
                                className="primary-inputs px-3"
                                rows="5"
                                placeholder="Enter Address"
                                onChange={(e) => handleChange(e)}
                                value={values.address}
                            ></textarea>
                        </div>
                        <button type="submit" className="main-btn mt-4">{isLoading === true ? "Submitting..." : "Submit"}</button>
                    </Col>
                </Row>
            </form>
        </>
    );
}

export default AddEmployee; 