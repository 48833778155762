import React, { useEffect } from "react";
import {
  FaExclamationCircle,
  FaBoxes,
  FaFileAlt,
  FaCheck,
} from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import Title from "../../../hook/Title";
import { BarChartComp } from "./Charts";
import UpComingEvents from "../../../Components/UpComingEvents";
import { showMyPortal } from '../../../actions/HrPortalAction';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

const analytics = [
  {
    id: 1,
    title: "Total Employees",
    detail: "6,578",
  },
  {
    id: 2,
    title: "Department",
    detail: "124",
  },
  {
    id: 3,
    title: "Expenses",
    detail: "$2,7853",
  },
];
const startingYear = [2022];
const cYear = new Date().getFullYear();
for (let i = 1; i <= 5; i++) {
  startingYear.push(cYear + i);
}


const Index = (props) => {
  Title("Dashboard");
  const navigate = useNavigate();
  const disPatch = useDispatch();
  const handleHrPortal = () => {
    disPatch(showMyPortal("showMyPortal"))
    navigate("/dashboard")
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <h4 className="primHeading my-3">HR <span> Dashboard</span></h4>
        <button className="sec-btn mb-3" onClick={handleHrPortal}>View My Dashboard</button>
      </div>
      <Row>
        <Col xxl={8}>
          <Row>
            {analytics.map((item, index) => {
              return (
                <Col
                  key={index}
                  lg={4}
                  sm={6}
                  className={`analyticsWrap${item.id} mb-2`}
                >
                  <div className="dashboard-anatlytics d-flex align-items-center justify-content-between flex-wrap">
                    <div>
                      <h3>{item.title}</h3>
                      <h4>{item.detail}</h4>
                    </div>
                    <div className={`analyticsIcon${item.id} analyticsIcon`}>
                      {item.id === 1 ? (
                        <FaFileAlt className="icons" />
                      ) : item.id === 2 ? (
                        <FaBoxes className="icons" />
                      ) : item.id === 3 ? (
                        <FaExclamationCircle className="icons" />
                      ) : item.id === 4 ? (
                        <FaCheck className="icons" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <div className="box my-3">
            {/* --------------------------  Overview chart ----------------------------- */}
            <div className="d-flex align-items-center flex-wrap justify-content-between">
              <h4 className="sectionHeading mb-3">Overview</h4>
              <div className="d-flex align-items-center justify-content-between my-2 flex-wrap">
                <div className="chartLabels m-2">
                  <span className="bgBlue"></span>
                  <p className="mb-0">Employees</p>
                </div>
                <div className="chartLabels m-2">
                  <span className="bgLgGray"></span>
                  <p className="mb-0">Attendance</p>
                </div>
                <select className="dashboard-inputs m-2">
                  {startingYear?.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <BarChartComp />
          </div>
        </Col>
        <Col xxl={4}>
          <UpComingEvents />
        </Col>
      </Row>
    </>
  );
};
export default Index;
