import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginScreen from "../Views/Auth/Login";
import ForgotPassword from "../Views/Auth/Forget";
import SetPassword from "../Views/Auth/SetPassword";
import Verification from "../Views/Auth/Verify";
import User from "../Views/User/Index";
import Dashboard from "../Views/Dashboard/Index";
import Leaves from "../Views/Leaves/Index";
import ApplyLeaves from "../Views/Leaves/ApplyLeaves";
import MyAttendance from "../Views/MyAttendance/Index";
import Notification from "../Views/Notification/Index";
import Layout from "../Layout/Layout";
import ErrorPage from "../Views/ErrorPage/Index";
import MyProfile from "../Views/Profile/MyProfile";
import EditProfile from "../Views/Profile/EditProfile";
import ChangePassword from "../Views/Profile/ChangePassword";
import Payslip from "../Views/Payslip/Index";
import HrDashboard from "../Views/hr/Dashboard/Index";
import Employees from "../Views/hr/Employees/Index";
import AddEmployee from "../Views/hr/Employees/AddEmployee";
import EditEmployee from "../Views/hr/Employees/EditEmployee";
import ViewEmployee from "../Views/hr/Employees/ViewEmployee";
import EmpAttendance from "../Views/hr/Employees/EmpAttendance";
import EmpOverview from "../Views/hr/Employees/EmpOverview";
import NoticeBoard from "../Views/hr/NoticeBoard/Index";
import Department from "../Views/hr/Department/Index";
import CreateAnnouncement from "../Views/hr/NoticeBoard/CreateAnnouncement";
import LeavesManagement from "../Views/hr/LeavesManagement/Index";
import Leavesapplication from "../Views/hr/LeavesManagement/Leavesapplication";
import { useSelector } from 'react-redux';

const MainRoutes = () => {
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const { role } = AuthReducer.data;

  return (
    <>
      <Routes>
        <Route exact path="/" element={<LoginScreen />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/verification/:email" element={<Verification />} />
        <Route exact path="/set-password/:email" element={<SetPassword />} />
        <Route path="*" element={<ErrorPage />} />
        {/*  ============================ Dashboard Pages ===============================   */}
        <Route
          exact
          path="/notification"
          element={
            <Layout>
              <Notification />
            </Layout>
          }
        />
        <Route
          exact
          path="/user"
          element={
            <Layout>
              <User />
            </Layout>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <Layout>
              <MyProfile />
            </Layout>
          }
        />
        <Route
          exact
          path="/edit-profile"
          element={
            <Layout>
              <EditProfile />
            </Layout>
          }
        />
        <Route
          exact
          path="/changepassword"
          element={
            <Layout>
              <ChangePassword />
            </Layout>
          }
        />
        <Route
          exact
          path="/dashboard"
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        />
        <Route
          exact
          path="/myleaves"
          element={
            <Layout>
              <Leaves />
            </Layout>
          }
        />
        <Route
          exact
          path="/applyLeaves"
          element={
            <Layout>
              <ApplyLeaves />
            </Layout>
          }
        />
        <Route
          exact
          path="/attendance"
          element={
            <Layout>
              <MyAttendance />
            </Layout>
          }
        />
        <Route
          exact
          path="/Payslip"
          element={
            <Layout>
              <Payslip />
            </Layout>
          }
        />
        {/* Conditional Routes Here */}
        {role === "hr" ? (
          <>
            <Route
              exact
              path="/hr-dashboard"
              element={
                <Layout>
                  <HrDashboard />
                </Layout>
              }
            />
            <Route
              exact
              path="/employees"
              element={
                <Layout>
                  <Employees />
                </Layout>
              }
            />
            <Route
              exact
              path="/add-employee"
              element={
                <Layout>
                  <AddEmployee />
                </Layout>
              }
            />
            <Route
              exact
              path="/edit-employee"
              element={
                <Layout>
                  <EditEmployee />
                </Layout>
              }
            />
            <Route
              exact
              path="/view-employee"
              element={
                <Layout>
                  <ViewEmployee />
                </Layout>
              }
            />
            <Route
              exact
              path="/emp-attendance"
              element={
                <Layout>
                  <EmpAttendance />
                </Layout>
              }
            />
            <Route
              exact
              path="/emp-overview"
              element={
                <Layout>
                  <EmpOverview />
                </Layout>
              }
            />
            <Route
              exact
              path="/notice-board"
              element={
                <Layout>
                  <NoticeBoard />
                </Layout>
              }
            />
            <Route
              exact
              path="/create-announcement"
              element={
                <Layout>
                  <CreateAnnouncement />
                </Layout>
              }
            />
            <Route
              exact
              path="/department"
              element={
                <Layout>
                  <Department />
                </Layout>
              }
            />
            <Route
              exact
              path="/leaves-management"
              element={
                <Layout>
                  <LeavesManagement />
                </Layout>
              }
            />
            <Route
              exact
              path="/Leaves-application"
              element={
                <Layout>
                  <Leavesapplication />
                </Layout>
              }
            />
          </>
        )
          :
          ""}
      </Routes>
    </>
  );
};

export default MainRoutes;
