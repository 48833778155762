// ======== BASE URL ===================== //
const config = require("../helpers/config.json");
// ===================================|| Auth Journey Apis ||=======================================//
export const loginRoute = `${config["BASEAPIURL"]}/api/users/login`;
export const forgetRoute = `${config["BASEAPIURL"]}/api/users/email-send`;
export const verificationRoute = `${config["BASEAPIURL"]}/api/users/validate-token`;
export const changePasswordRoute = `${config["BASEAPIURL"]}/api/users/change-password`; 
export const createEmployeeRoute = `${config["BASEAPIURL"]}/api/users`; 
// ===================================|| - Apis ||========================================// 
// ===================================|| - Apis ||========================================// 
// ===================================|| - Apis ||========================================// 
// ===================================|| - Apis ||========================================//  