import React, { useState, useEffect } from "react";
import {
    FaTrash,
    FaEdit,
    FaBuilding,
    FaTimes
} from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import Title from "../../../hook/Title";
import { TableCom } from "../../../Components/TableCom";
import CustomModal from "../../../Components/CustomModal";
import { toast } from "react-toastify";



const Index = () => {

    const [modalShow, setModalShow] = useState(false);
    const [editModalShow, seteditModalShow] = useState(false);

    // Table Head Data
    const Thead = [
        "S.No",
        "Department ID",
        "Department Name",
        "Actions",
    ];
    // ======================================= 
    const [thLength, setThlength] = useState(1);
    const [items, setItems] = useState([]);
    const [limit, setLimit] = useState(5);
    const [totalData, setTotalData] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [searchVal, setSearchVal] = useState("");
    const BASEURL = "http://jsonplaceholder.typicode.com/posts";
    useEffect(
        (currentPage) => {
            const getData = async () => {
                const res = await fetch(
                    `${BASEURL}/?q=${searchVal}&?_page=${currentPage}&_limit=${limit}`
                );
                const data = await res.json();
                const total = res.headers.get("x-total-count");
                setTotalData(total);
                setpageCount(Math.ceil(total / limit));
                setItems(data);
            };
            getData();
        },
        [limit, searchVal]
    );
    useEffect(() => {
        setThlength(Thead.length);
    }, [thLength]);
    const fetchData = async (currentPage) => {
        const res = await fetch(`${BASEURL}?_page=${currentPage}&_limit=${limit}`);
        const data = await res.json();
        return data;
    };
    const handlePageClick = async (data) => {
        console.log(data.selected);
        let currentPage = data.selected + 1;
        const myData = await fetchData(currentPage);
        setItems(myData);
    };
    const handleChange = (e) => {
        setLimit(e.target.value);
    };
    // search data in api using search box
    const hangleSearchVal = (e) => {
        setSearchVal(e.target.value);
    };
    Title("Dashboard");
    // Show Toast msg if Dep Created Sucessfully
    const showDepartmentToast = () => {
        toast.success("Department Created Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });
    };
    // Show Toast msg if Dep Updated Sucessfully
    const showEditDepartmentToast = () => {
        toast.success("Department Updated Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });
    };
    return (
        <>
            <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
                <h4 className="primHeading my-2">Departments</h4>
                <button className="main-btn  my-2" onClick={() => setModalShow(true)} >Create New Department</button>
            </div>
            <Row>
                <Col>
                    <div className="box">
                        <h6 className="sectionHeading my-2 mb-4">Department Summary</h6>
                        <hr className="my-4" />
                        {/* Table start here */}
                        <TableCom
                            limit={limit}
                            totalData={totalData}
                            handleChange={handleChange}
                            hangleSearchVal={hangleSearchVal}
                            pageCount={pageCount}
                            handlePageClick={handlePageClick}>
                            <div className="customTable2 w-100 table-responsive">
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                            {Thead?.map((th, index) => {
                                                return <th key={index}>{th}</th>;
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            items == "" || items == undefined || items == null ? (
                                                <tr>
                                                    <td colSpan={thLength}>
                                                        <p className="my-2 smText text-decoration-underline text-danger">
                                                            No Record Found
                                                        </p>
                                                    </td>
                                                </tr>
                                            ) : (
                                                items.map((item) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{item.id}</td>
                                                            <td>
                                                                {item.title.slice(0, 3)}
                                                            </td>
                                                            <td>{item.title.slice(0, 5)}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <button className="actionIcon bgGreen" onClick={() => seteditModalShow(true)}>
                                                                        <FaEdit />
                                                                    </button>
                                                                    <button className="actionIcon bgRed border-none">
                                                                        <FaTrash />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </TableCom>
                    </div>
                </Col>
            </Row>
            {/* ======== Create Department Modal ======== */}
            <CustomModal show={modalShow} onHide={() => setModalShow(false)}>
                <button onClick={() => setModalShow(false)} className="modal-cancel">
                    <FaTimes />
                </button>
                <div className="modalContent">
                    <div className="text-start">
                        <h3 className="modalPrimHeading">Create Department</h3>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="depName">
                                Department Name<span className="text-danger">*</span>
                            </label>
                            <div className="position-relative">
                                <button
                                    className="position-absolute show-password"
                                    type="button">
                                    <FaBuilding />
                                </button>
                                <input
                                    id="depName"
                                    type="text"
                                    placeholder="Enter Department Name"
                                    className="primary-inputs"
                                />
                            </div>
                        </div>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="subDepName">Sub-Department Name</label>
                            <div className="position-relative">
                                <button className="position-absolute show-password" type="button"><FaBuilding /></button>
                                <input
                                    id="subDepName"
                                    type="text"
                                    placeholder="Enter Sub-Department Name"
                                    className="primary-inputs"
                                />
                            </div>
                        </div>
                    </div>
                    {/* modal buttons */}
                    <button className="main-btn m-2" type="button" onClick={() => { setModalShow(false); showDepartmentToast() }}>Submit</button>
                    <button className="sec-btn m-2" type="button" onClick={() => setModalShow(false)}>Close</button>
                </div>
            </CustomModal>
            {/* ======== Edit Department Modal ======== */}
            <CustomModal show={editModalShow} onHide={() => seteditModalShow(false)}>
                <button onClick={() => seteditModalShow(false)} className="modal-cancel">
                    <FaTimes />
                </button>
                <div className="modalContent">
                    <div className="text-start">
                        <h3 className="modalPrimHeading">Edit Department</h3>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="depName">
                                Department Name<span className="text-danger">*</span>
                            </label>
                            <div className="position-relative">
                                <button
                                    className="position-absolute show-password"
                                    type="button">
                                    <FaBuilding />
                                </button>
                                <input
                                    id="depName"
                                    type="text"
                                    placeholder="Enter Department Name"
                                    className="primary-inputs"
                                />
                            </div>
                        </div>
                        <div className="registration-inp-box my-3">
                            <label className="d-block" htmlFor="subDepName">Sub-Department Name</label>
                            <div className="position-relative">
                                <button className="position-absolute show-password" type="button"><FaBuilding /></button>
                                <input
                                    id="subDepName"
                                    type="text"
                                    placeholder="Enter Sub-Department Name"
                                    className="primary-inputs"
                                />
                            </div>
                        </div>
                    </div>
                    {/* modal buttons */}
                    <button className="main-btn m-2" type="button" onClick={() => { seteditModalShow(false); showEditDepartmentToast() }}>Update</button>
                    <button className="sec-btn m-2" type="button" onClick={() => seteditModalShow(false)}>Close</button>
                </div>
            </CustomModal>
        </>
    );
};
export default Index;