import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import configureStore from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { applyMiddleware, compose, createStore } from 'redux';
import mainReducer from '../src/reducers/rootReducer'
import persistStore from 'redux-persist/es/persistStore';
import thunk from 'redux-thunk';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from './reportWebVitals';
const store = createStore(mainReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
reportWebVitals(); 
