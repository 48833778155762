import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Title from "../../../hook/Title";
import { TableCom } from "../../../Components/TableCom";

const EmpOverview = () => {
    const startingYear = [2022];
    const cYear = new Date().getFullYear();
    for (let i = 1; i <= 5; i++) {
        startingYear.push(cYear + i);
    }
    // Table Head Data
    const Thead = ["SNo", "Employee Name", "Last Absent", "Half Day", "Sick", "Casual", "Maternity", "Paternity", "Annual", "Unpaid", "Other"];
    // ======================================= 
    const [thLength, setThlength] = useState(1);
    const [items, setItems] = useState([]);
    const [limit, setLimit] = useState(5);
    const [totalData, setTotalData] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [searchVal, setSearchVal] = useState("");
    const BASEURL = "http://jsonplaceholder.typicode.com/posts";
    useEffect(
        (currentPage) => {
            currentPage = currentPage ?? 0
            const getData = async () => {
                const res = await fetch(
                    `${BASEURL}/?q=${searchVal}&?_page=${currentPage}&_limit=${limit}`
                );
                const data = await res.json();
                const total = res.headers.get("x-total-count");
                setTotalData(total);
                setpageCount(Math.ceil(total / limit));
                setItems(data);
            };
            getData();
        },
        [limit, searchVal]
    );
    useEffect(() => {
        setThlength(Thead.length);
    }, [thLength]);
    const fetchData = async (currentPage) => {
        const res = await fetch(`${BASEURL}?_page=${currentPage}&_limit=${limit}`);
        const data = await res.json();
        return data;
    };
    const handlePageClick = async (data) => {
        console.log(data.selected);
        let currentPage = data.selected + 1;
        const myData = await fetchData(currentPage);
        setItems(myData);
    };
    const handleChange = (e) => {
        setLimit(e.target.value);
    };
    // search data in api using search box
    const hangleSearchVal = (e) => {
        setSearchVal(e.target.value);
    };
    Title("Attendance Overview");
    return (
        <>
            <h4 className="primHeading my-2 mb-4">Attendance Overview</h4>
            <Row>
                <Col>
                    <div className="box">
                        {/* Filters start here */}
                        <Row className="customFilters mb-4">
                            <Col xl={3} lg={4} md={6} sm={6} className="my-2">
                                <label className="text-bold" htmlFor="Month">
                                    Employee Name:
                                </label>
                                <select className="filterSelect">
                                    <option disabled>Select Employee</option>
                                    <option value="abc">Abc</option>
                                    <option value="abc">Def</option>
                                    <option value="abc">xyz</option>
                                </select>
                            </Col>
                            <Col xl={2} lg={4} md={6} sm={6} className="my-2">
                                <label className="text-bold" htmlFor="Year">
                                    Month:
                                </label>
                                <input type="date" className="filterSelect" name="date" />
                            </Col>
                            <Col xl={2} lg={4} md={6} sm={6} className="my-2">
                                <label className="text-bold" htmlFor="Year">
                                    Date:
                                </label>
                                <select className="filterSelect">
                                    <option disabled> Select Month </option>
                                    <option value="January">January</option>
                                    <option value="feb">Feb</option>
                                    <option value="march">march</option>
                                </select>
                            </Col>
                            <Col xl={3} lg={4} md={6} sm={6} className="my-2">
                                <label className="text-bold" htmlFor="Year">
                                    Year:
                                </label>
                                <select className="filterSelect">
                                    <option disabled>Select Year</option>
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                    <option value="2020">2020</option>
                                    <option value="2019">2019</option>
                                </select>
                            </Col>
                            <Col xl={2} lg={4} md={6} sm={6} className="my-2">
                                <button className="main-btn w-100 mt-4">Search</button>
                            </Col>
                        </Row>
                        {/* Table start here */}
                        <TableCom
                            limit={limit}
                            totalData={totalData}
                            handleChange={handleChange}
                            hangleSearchVal={hangleSearchVal}
                            pageCount={pageCount}
                            handlePageClick={handlePageClick}
                        >
                            <div className="customTable2 w-100 table-responsive">
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                            {Thead?.map((th, index) => {
                                                return <th key={index}>{th}</th>;
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items == "" || items == undefined || items == null ? (
                                            <tr>
                                                <td colSpan={thLength}>
                                                    <p className="my-2 smText text-decoration-underline text-danger">
                                                        No Record Found
                                                    </p>
                                                </td>
                                            </tr>
                                        ) : (
                                            items.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.id}</td>
                                                        <td>
                                                            <img
                                                                className="tdAvatar"
                                                                src={require("../../../assets/images/avatar.png")}
                                                                alt="assigned user image"
                                                            />
                                                            {item.title.slice(0, 5)}
                                                        </td>
                                                        <td>12</td>
                                                        <td>08</td>
                                                        <td>3</td>
                                                        <td>05</td>
                                                        <td>00</td>
                                                        <td>00</td>
                                                        <td>25</td>
                                                        <td>03</td>
                                                        <td>6</td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </TableCom>
                    </div>
                </Col>
            </Row>
        </>
    );
};
export default EmpOverview;