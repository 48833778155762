import React, { useState, useEffect } from "react";
import {
    FaTrash,
    FaTimes,
    FaEye
} from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import Title from "../../../hook/Title";
import { TableCom } from "../../../Components/TableCom";
import CustomModal from "../../../Components/CustomModal";
import { Link } from "react-router-dom";
const Index = () => {
    const [noticeModal, setNoticeModalShow] = useState(false);
    const [createNoticeModal, setCreateNoticeModal] = useState(false);
    // Table Head Data
    const Thead = [
        "S.No",
        "Title",
        "Description",
        "Create On",
        "Action",
    ];
    // ========================================================= 
    const [thLength, setThlength] = useState(1);
    const [items, setItems] = useState([]);
    const [limit, setLimit] = useState(5);
    const [totalData, setTotalData] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [searchVal, setSearchVal] = useState("");
    const BASEURL = "http://jsonplaceholder.typicode.com/posts";
    useEffect(
        (currentPage) => {
            currentPage = currentPage ?? 0
            const getData = async () => {
                const res = await fetch(
                    `${BASEURL}/?q=${searchVal}&?_page=${currentPage}&_limit=${limit}`
                );
                const data = await res.json();
                const total = res.headers.get("x-total-count");
                setTotalData(total);
                setpageCount(Math.ceil(total / limit));
                setItems(data);
            };
            getData();
        },
        [limit, searchVal]
    );
    useEffect(() => {
        setThlength(Thead.length);
    }, [thLength]);
    const fetchData = async (currentPage) => {
        const res = await fetch(`${BASEURL}?_page=${currentPage}&_limit=${limit}`);
        const data = await res.json();
        return data;
    };
    const handlePageClick = async (data) => {
        console.log(data.selected);
        let currentPage = data.selected + 1;
        const myData = await fetchData(currentPage);
        setItems(myData);
    };
    const handleChange = (e) => {
        setLimit(e.target.value);
    };
    // search data in api using search box
    const hangleSearchVal = (e) => {
        setSearchVal(e.target.value);
    };
    Title("Notice Board");
    return (
        <>
            <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
                <h4 className="primHeading my-2">Notice Board</h4>
                <Link className="main-btn my-2" to="/create-announcement">Create New Notice</Link>
            </div>
            <Row>
                <Col>
                    <div className="box">
                        <h6 className="sectionHeading my-2 mb-4">Notice Summary</h6>
                        <hr className="my-4" />
                        {/* Table start here */}
                        <TableCom
                            limit={limit}
                            totalData={totalData}
                            handleChange={handleChange}
                            hangleSearchVal={hangleSearchVal}
                            pageCount={pageCount}
                            handlePageClick={handlePageClick}>
                            <div className="customTable2 w-100 table-responsive">
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                            {Thead?.map((th, index) => {
                                                return <th key={index}>{th}</th>;
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            items == "" || items == undefined || items == null ? (
                                                <tr>
                                                    <td colSpan={thLength}>
                                                        <p className="my-2 smText text-decoration-underline text-danger">
                                                            No Record Found
                                                        </p>
                                                    </td>
                                                </tr>
                                            ) : (
                                                items.map((item) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{item.id}</td>
                                                            <td>{item.title.slice(0, 3)}  </td>
                                                            <td>{item.title.slice(0, 35)}</td>
                                                            <td>12-09-2022</td>
                                                            <td>
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <button className="actionIcon bgGreen" onClick={() => setNoticeModalShow(true)}>
                                                                        <FaEye />
                                                                    </button>
                                                                    <button className="actionIcon bgRed border-none">
                                                                        <FaTrash />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </TableCom>
                    </div>
                </Col>
            </Row>
            {/* ======== Show Notice Modal ======== */}
            <CustomModal show={noticeModal} onHide={() => setNoticeModalShow(false)}>
                <button onClick={() => setNoticeModalShow(false)} className="modal-cancel">
                    <FaTimes />
                </button>
                <div className="modalContent">
                    <div className="text-start">
                        <h3 className="modalPrimHeading">Notification</h3>
                        <div className="registration-inp-box my-3">
                            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit optio, vero quos officia odio eaque dolor, iure enim natus voluptate magni temporibus. Corrupti nisi, placeat beatae minima dignissimos veniam adipisci!Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit optio, vero quos officia odio eaque dolor, iure enim natus voluptate magni temporibus. Corrupti nisi, placeat beatae minima dignissimos veniam adipisci!Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit optio, vero quos officia odio eaque dolor, iure enim natus voluptate magni temporibus. Corrupti nisi, placeat beatae minima dignissimos veniam adipisci!Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit optio, vero quos officia odio eaque dolor, iure enim natus voluptate magni temporibus. Corrupti nisi, placeat beatae minima dignissimos veniam adipisci!</p>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </>
    );
};
export default Index;