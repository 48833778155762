import React, { useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Title from "../../../hook/Title";
import GoBack from "../../../Components/GoBack";
import { useNavigate } from "react-router-dom";
import BASEURL from "../../../BaseUrl";
import { Link } from "react-router-dom";
import ProfileCard from "../../../Components/ProfileCard";

import {
    FaUserAlt,
    FaEnvelope,
    FaAddressCard,
    FaSortNumericDownAlt
} from "react-icons/fa";


const ViewEmployee = () => {
    const [selected, setSelected] = useState("male");
    const [selectedFile, setSelectedFile] = useState(null);

    Title("View Employee Data");
    const navigate = useNavigate();
    const fileInput = useRef(null);
    // Select input file from your PC
    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    return (
        <>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="d-flex align-items-center mb-2 flex-wrap">
                    <GoBack />
                    <h4 className="primHeading mb-0">Employee<span> Profile </span></h4>
                </div>
                <Link className="main-btn  mb-2" to="/add-employee">Add New Employee</Link>
            </div> 
            <Row>
                <Col xxl={4} xl={5}>
                    <div className="box mt-5">
                        <ProfileCard />
                    </div>
                </Col>
                <Col xxl={8} xl={7}>
                    <div className="box mt-5">
                        <Row>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="Name">
                                        Employee Name<span className="text-danger">*</span>
                                    </label>
                                    <div className="position-relative">
                                        <button
                                            className="position-absolute show-password"
                                            type="button">
                                            <FaUserAlt />
                                        </button>
                                        <input
                                            disabled
                                            id="Name"
                                            type="text"
                                            placeholder="Enter Full Name"
                                            className="primary-inputs"
                                            value="Alex"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="fName">
                                        Father Name<span className="text-danger">*</span>
                                    </label>
                                    <div className="position-relative">
                                        <button
                                            className="position-absolute show-password"
                                            type="button">
                                            <FaUserAlt />
                                        </button>
                                        <input
                                            disabled
                                            id="fName"
                                            type="text"
                                            placeholder="Enter Father Name"
                                            className="primary-inputs"
                                            value="Hales"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="email">
                                        Email<span className="text-danger">*</span>
                                    </label>
                                    <div className="position-relative">
                                        <button
                                            className="position-absolute show-password"
                                            type="button">
                                            <FaEnvelope />
                                        </button>
                                        <input
                                            disabled
                                            id="email"
                                            type="email"
                                            placeholder="Enter Email"
                                            className="primary-inputs"
                                            value="alex@gmail.com"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="phone">
                                        Phone Number<span className="text-danger">*</span>
                                    </label>
                                    <div className="position-relative">
                                        <button
                                            className="position-absolute show-password"
                                            type="button">
                                            <FaSortNumericDownAlt />
                                        </button>
                                        <input
                                            disabled
                                            id="phone"
                                            type="number"
                                            placeholder="Enter Phone Number"
                                            className="primary-inputs"
                                            value="0147896325"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="department">
                                        Department <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        name="department"
                                        id="department"
                                        className="primary-inputs px-2"
                                        value="Web & App">
                                        <option value="#" disabled>
                                            Select Department
                                        </option>
                                        <option value="admin">Admin</option>
                                        <option value="sales">Sales</option>
                                        <option value="development">Web & App</option>
                                        <option value="hr">Human Resource</option>
                                        <option value="finance">Finance</option>
                                    </select>

                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="subDepartment">
                                        Sub Department <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        name="subDepartment"
                                        id="subDepartment"
                                        className="primary-inputs px-2"
                                        value="Frontend">
                                        <option value="#">
                                            Select Department
                                        </option>
                                        <option value="uiux">UI/UX Designing</option>
                                        <option value="logo">Logo Designing</option>
                                        <option value="frontend">Frontend Developer</option>
                                        <option value="cms">CMS Developer</option>
                                        <option value="mern">Mern Stack Developer</option>
                                        <option value="backend">Backend Developer</option>
                                        <option value="fullStack">Full Stack Developer</option>
                                        <option value="app">App Developer</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="designation">
                                        Designation <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        name="designation"
                                        id="designation"
                                        className="primary-inputs px-2"
                                        value="Associate Manager"
                                    >
                                        <option value="#">
                                            Select Designation
                                        </option>
                                        <option value="intern">Intern</option>
                                        <option value="junior">Junior</option>
                                        <option value="mid">Mid</option>
                                        <option value="senior">Senior</option>
                                        <option value="atl">Associate Team Lead</option>
                                        <option value="tl">Team Lead</option>
                                        <option value="am">Associate Manager</option>
                                        <option value="manager">Manager</option>
                                        <option value="hod">Head of Department</option>
                                        <option value="avp">Associate Vice President</option>
                                        <option value="vp">Vice President</option>
                                        <option value="president">President</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="salary">
                                        Salary<span className="text-danger">*</span>
                                    </label>
                                    <div className="position-relative">
                                        <button
                                            className="position-absolute show-password"
                                            type="button">
                                            <FaSortNumericDownAlt />
                                        </button>
                                        <input
                                            disabled
                                            id="salary"
                                            type="number"
                                            placeholder="Enter Salary"
                                            className="primary-inputs"
                                            value="95000"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="meritialStatus">
                                        Marital Status <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        name="meritialStatus"
                                        id="meritialStatus"
                                        className="primary-inputs px-2"
                                        value="Single"
                                    >
                                        <option value="#" disabled>
                                            Select
                                        </option>
                                        <option value="single">Single</option>
                                        <option value="married">Married</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="cnic">
                                        Cnic <span className="text-danger">*</span>
                                    </label>
                                    <div className="position-relative">
                                        <button
                                            className="position-absolute show-password"
                                            type="button">
                                            <FaAddressCard />
                                        </button>
                                        <input
                                            disabled
                                            id="cnic"
                                            type="number"
                                            placeholder="Enter Cnic Number"
                                            className="primary-inputs"
                                            value="5221478523694"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="dob">
                                        Date Of Birth<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        disabled
                                        type="date"
                                        className="prim-date"
                                        name="dateOfJoining"
                                        id="dob"
                                        value="2-8-2000"
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="jod">
                                        Joining Date<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        disabled
                                        type="date"
                                        className="prim-date"
                                        name="dateOfJoining"
                                        id="jod"
                                        value="12-8-2022"
                                    />
                                </div>
                            </Col>
                            {/* <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="profile">Upload profile</label>
                                    <input
                                        disabled type="file" ref={fileInput} id="profile" className="d-none" name="file" onChange={changeHandler} />
                                    <div className="p-0 primary-inputs d-flex justify-content-between align-items-center">
                                        <p className="mb-0 mx-3 exsmText">{selectedFile == null ? "No file chosen" : selectedFile.name}</p>
                                        <button className="formUploadBtn" onClick={() => fileInput.current.click()}>Choose File</button>
                                    </div>
                                </div>
                            </Col> */}
                            <Col lg={6}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block">
                                        Gender<span className="text-danger">*</span>
                                    </label>
                                    <div className="d-flex align-items-center flex-wrap">
                                        <label className="radioWrap my-2 me-5">
                                            <input
                                                disabled
                                                type="radio"
                                                name="radio"
                                                value="male"
                                                checked
                                            />
                                            <span className="chkmark"></span>
                                            Male
                                        </label>
                                        <label className="radioWrap my-2 me-5">
                                            <input
                                                disabled
                                                type="radio"
                                                name="radio"
                                                value="female"
                                            />
                                            <span className="chkmark"></span>
                                            Female
                                        </label>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="registration-inp-box my-3">
                                    <label className="d-block" htmlFor="address">Address<span className="text-danger">*</span> </label>
                                    <textarea
                                        disabled
                                        name="address"
                                        id="address"
                                        className="primary-inputs px-3"
                                        rows="5"
                                        placeholder="Enter Address"
                                        value="lorem ipsom is simplay a dummy text"
                                    ></textarea>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ViewEmployee; 